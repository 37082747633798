import {Component} from 'react';
import {PropTypes} from 'prop-types';
import {Link} from 'react-router';
import AuthActions from 'actions/AuthActions';
import _ from 'lodash';
import {Navbar, Nav, NavItem, NavDropdown, MenuItem} from 'react-bootstrap';

export default class HeaderDetails extends Component {

    static contextTypes = {
        router: PropTypes.object.isRequired
    };

    logout = (e) => {
        e.preventDefault();
        AuthActions.logout.defer();
    };

    editPatterns = (e) => {
        e.preventDefault();
        return this.context.router.push('/managepatterns');
    };

    editProfile = (e) => {
        e.preventDefault();
        return this.context.router.push('/profile');
    };

    isAdmin = () => {
        if(this.props.isAuth && this.props.user && (this.props.user.role === 'Admin' || this.props.user.role === 'Package Manager')){
            return true;
        }else {
            return false;
        }
    };

    toggleSideBar = () => {

        const hasSideBarCollapsed =_.find(document.body.classList, (item) => item === 'sidebar-collapse');
        if(hasSideBarCollapsed){
            document.body.classList.remove('sidebar-collapse');
        }else{
            document.body.classList.toggle('sidebar-collapse', true)
        }
    };

    render() {
        return (
                <header id="chrome" className="main-header">

                    <Link to="/" className="logo"><span className="logo-lg">iDocData</span></Link>

                    <Navbar fixedTop fluid>
                        <Navbar.Header>
                            <Nav>
                                <li>
                                    <a onClick={this.toggleSideBar} className="sidebar-toggle" data-toggle="offcanvas" role="button">
                                        <span className="sr-only">Toggle navigation</span>
                                    </a>
                                </li>
                                <li>
                                    <Navbar.Brand>
                                        { !this.props.isAuth ? null :
                                            <h1>{this.props.pageTitle}</h1>
                                        }
                                    </Navbar.Brand>
                                </li>
                                <li>
                                    <Navbar.Toggle />
                                </li>
                            </Nav>
                        </Navbar.Header>
                        <Navbar.Collapse>
                            {!this.props.user ? null :
                                <Nav pullRight>
                                    <NavDropdown eventKey={"user-name"} title={this.props.user.name} id="basic-nav-dropdown">
                                        <MenuItem eventKey={"edit-profile"} onClick={this.editProfile}>Edit Profile</MenuItem>
                                        <MenuItem eventKey={"sign-out"} onClick={this.logout}>Sign Out</MenuItem>
                                    </NavDropdown>

                                    <li className="admin">{this.isAdmin() === false ? null :
                                        <Link to="/admin"><i className="fa fa-gear" title="Admin"/></Link>
                                    }</li>
                                </Nav>

                            }
                        </Navbar.Collapse>
                    </Navbar>



                </header>
        )
    }
}

