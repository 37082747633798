import alt from 'libs/alt';
import _ from 'lodash';
import GrowlNotificationActions from 'actions/GrowlNotificationActions';

class GrowlNotificationStore {
    constructor() {
        this.bindActions(GrowlNotificationActions);
        this.notification = {};
    }

    sendNotification(notification) {
        this.setState({ notification: notification });
    }

    sendError(err){
        this.sendNotification({
            type: "error",
            headline: "Error",
            message: _.get(err, 'response.data.message', err || 'An error has occurred')
        });
    }

    clearNotification(){
        this.setState({notification: {}});
    }
}

export default alt.createStore(GrowlNotificationStore, 'GrowlNotificationStore');
