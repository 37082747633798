import axios from 'axios';
import _ from 'lodash';
import AuthStore from 'stores/AuthStore';
import AuthActions from 'actions/AuthActions';
import GrowlNotificationActions from 'actions/GrowlNotificationActions';

axios.interceptors.request.use(function (config) {
    if(AuthStore.getState().isAuth){
        config.headers = {'Authorization': `Bearer ${AuthStore.getState().jwt}`}
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    //handle unauthorized...
    console.error('response interceptor error', [error]);
    if(!error.response || _.get(error, 'response.status') === 504 ) {
        return window.setTimeout(() =>
            GrowlNotificationActions.sendNotification({
                type: "error",
                message: "Internet is disconnected - changes will not be saved"
            }), 100);
    }
    if(_.get(error, 'response.status') === 401){
        console.log('logout');
        return AuthActions.logout(); //this will clear out any client side token and redirect to login...
    }
    if(_.get(error, 'response.status') === 403) {
        return browserHistory.replace('/');
    }


    return Promise.reject(error);
});

export default axios;
