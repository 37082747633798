import alt from 'libs/alt';
import axios from 'libs/axios';

import GrowlNotificationActions from './GrowlNotificationActions';

class AuthActions {
    constructor() {
        this.generateActions(
            'loginSuccess',
            'loginFailed',
            'logout',
            'forgotPasswordFailed',
            'forgotPasswordSuccess',
            'clearErrorMessage',
            'setPageTitle',
            'setPageTitleAdmin',
            'requestEmailSuccess',
            'requestEmailFailed',
            'refreshSuccess'
        );
    }

    login(email, password) {
        return axios.post('/api/auth', {email, password}).then(response => {
            this.loginSuccess(response.data);
        }).catch(errorMessage => {
            if (errorMessage.data && errorMessage.data.message) {
                this.loginFailed(errorMessage.data.message);
            } else {
                this.loginFailed("Error - please check your credentials");
            }
        });
    }

    forgotPassword(email){
        return axios.post('/api/email/forgotpassword',{email}).then(response => {
            this.forgotPasswordSuccess(response.data);
        }).catch(errorMessage => {
            if (errorMessage.data && errorMessage.data.message){
                this.forgotPasswordFailed(errorMessage.data.message);
            }else{
                this.forgotPasswordFailed("Error requesting password - see your administrator");
            }
        });
    }

    requestEmail(info) {
        return axios.post('/api/email/request', info).then(response => {
            GrowlNotificationActions.sendNotification({
                type: "info",
                headline: "Information Request",
                message: "Your information has been submitted."
            });
            this.requestEmailSuccess(response.data);
        }).catch(errorMessage => {
            if (errorMessage.data && errorMessage.data.message){
                this.requestEmailFailed(errorMessage.data.message);
            }else{
                this.requestEmailFailed("Error submitting your information.");
            }
        });
    }

    updateProfile(info) {
        return axios.put('/api/profile', info).then(response => {
            GrowlNotificationActions.sendNotification({
                type: "info",
                headline: "Profile Updated",
                message: "Your profile has been updated."
            });
            return axios.post('/api/auth/refresh').then(response => {
                this.refreshSuccess(response.data);
            });
        });
    }

}

export default alt.createActions(AuthActions);
