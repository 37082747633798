import 'styles/global.less';
import 'babel-polyfill';
import {render} from 'react-dom';
import { Router, browserHistory, applyRouterMiddleware} from 'react-router'
import alt from 'libs/alt';
import storage from 'libs/storage';
import persist from 'libs/persist';
import routeIndex from './routes/index-route';

import {useHistoryRestoreScroll, useRouterRestoreScroll} from 'react-router-restore-scroll';

persist(alt, storage, 'app');


const createHistory = useHistoryRestoreScroll(() => browserHistory);
const routerRender = applyRouterMiddleware(
    useRouterRestoreScroll()
);

let appTarget = document.createElement('div');
appTarget.setAttribute('class', 'app');
document.body.appendChild(appTarget);

render((
    <Router history={createHistory()} render={routerRender} routes={routeIndex} />
),appTarget);




