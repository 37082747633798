import App from 'components/App';
import AuthActions from 'actions/AuthActions';
import AuthStore from 'stores/AuthStore';

module.exports = {
    path: '/',
    component: App,
    getIndexRoute(location, callback) {
        require.ensure([], function (require) {
            if (!AuthStore.getState().isAuth) {
                callback(null, {
                    components: {
                        content: require('components/Login.jsx'),
                        sidebar: null
                    },
                    onEnter: (next, replace) => AuthActions.setPageTitle.defer()}
                );
            } else {
                callback(null, {
                    components: {
                        content: require('components/appointment/Appointments'),
                        sidebar: require('components/sidebar/AppointmentsSideBar')
                    },
                    onEnter: (next, replace) => {
                        let aa = require('actions/AppointmentsActions');
                        aa.getReports();
                        return aa.getInitializedAppointments(next.location.query)
                            .then(() => AuthActions.setPageTitle('Appointments'))
                    }
                });

            }
        })
    },
    getChildRoutes(location, callback) {
        require.ensure([], require => {
            callback(null, require('./main-route.jsx'))
        })
    }
};
