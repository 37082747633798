import alt from 'libs/alt';

class GrowlNotificationActions {

    constructor(){
        this.generateActions(
            'sendNotification',
            'clearNotification',
            'sendError'
        );
    }
}

export default alt.createActions(GrowlNotificationActions);
