import {Component} from 'react';
import humane from 'humane-js';

import GrowlNotificationActions from 'actions/GrowlNotificationActions';

export default class GrowlNotifier extends Component {
    constructor() {
        super();
        this.options = {
            timeout: 5000,
            clickToClose: true,
            baseCls: 'libnotify'
        }
    }

    displayNotification = (notification) => {
        if(this.props.notification && this.props.notification.type){
            switch(this.props.notification.type) {
                case 'info':
                    humane.log(this.props.notification.message, this.options).addnCls = 'humane-info';
                    break;
                case 'error':
                    humane.log(this.props.notification.message, this.options).addnCls = 'humane-error';
                    break;
                default:
                    humane.log(this.props.notification.message, this.options).addnCls = 'humane-default';
            }
            GrowlNotificationActions.clearNotification.defer(this.props.notification);
        }
    };

    render() {
        this.displayNotification(this.props.notification);
        return (
            <span></span>
        )
    }
}
