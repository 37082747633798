import {Component} from 'react';
import PropTypes from 'prop-types';
import Header from './header/Header';
import AuthStore from 'stores/AuthStore';
import AltContainer from 'alt-container';
import GrowlNotificationStore from 'stores/GrowlNotificationStore';
import GrowlNotifier from 'components/notifications/GrowlNotifier';

export default class App extends Component {
    constructor(){
        super();

        this.build = __VERSION__;
    }

    static childContextTypes = {
        isAuth: PropTypes.bool,
        user: PropTypes.object
    };

    getChildContext () {
        return {
            isAuth: AuthStore.getState().isAuth,
            user: AuthStore.getState().user
        };
    }

    componentDidMount() {
        document.body.className = 'skin-blue sidebar-mini';
    }

    render() {
        const user = AuthStore.getState().user;
        return (

        <AltContainer store={GrowlNotificationStore}>
            <GrowlNotifier />
            <div>

                {user ?
                    <div className={this.props.sidebar ? 'skin-blue' : 'skin-blue sidebar-collapse'}>
                        <Header headerTitle={this.props.headerTitle} />

                        {this.props.sidebar}

                        <div className="content-wrapper">
                            <section className="content">
                                {this.props.content}
                            </section>
                        </div>

                        <footer className="main-footer">
                            <div className="pull-right hidden-xs">
                                <b>Build:</b> {this.build}
                            </div>
                        </footer>
                    </div>

                    :

                    // Login screen only needs the content area
                    <div id="login" className="newsletter row bg-login">
                        {this.props.content}
                    </div>
                }
            </div>
        </AltContainer>
        );
    }
}


