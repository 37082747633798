import alt from 'libs/alt';
import { browserHistory }  from 'react-router';
import storage from 'libs/storage';
import AuthActions from 'actions/AuthActions';
import jwtDecode from 'jwt-decode';

class AuthStore {
    constructor() {
        this.bindActions(AuthActions);
        this.isAuth = false;
        this.jwt = null;
        this.user = null;
        this.errorMessage = "";
        this.pageTitle = 'Appointments';
    }

    setPageTitle(title) {
        this.setState({
            pageTitle: title || 'Appointments'
        })
    }

    setPageTitleAdmin(title) {
        this.setState({
            pageTitle: title || 'iDocData Administration'
        })
    }

    loginSuccess(data) {

        this.setState({
            isAuth: true,
            jwt: data.token,
            user: jwtDecode(data.token)
        });

        try {
            window.setTimeout(() => browserHistory.push('/'), 0);
        }catch(err){
            console.error('login error', err);
        }
    }

    refreshSuccess(data) {
        this.setState({
            isAuth: true,
            jwt: data.token,
            user: jwtDecode(data.token)
        });
    }

    logout(url) {

        console.info('logout');
        this.setState({
            isAuth: false,
            jwt: null,
            user: null
        });
        storage.remove('app');
        return browserHistory.push(url || '/home');
    }

    forgotPasswordSuccess() {
        this.setState({
            errorMessage: null
        });
        //send back to login
        try {
            window.setTimeout(() => browserHistory.push('/home'), 0);
        }catch(err){
            console.error('login error', err);
        }
    }

    loginFailed(errorMessage){
        this.setState({
            errorMessage: errorMessage
        });
    }

    forgotPasswordFailed(errorMessage){
        this.setState({
            errorMessage: errorMessage
        });
    }

    clearErrorMessage(message){
        this.setState({
            errorMessage: message
        });
    }

    requestEmailSuccess(){
        this.setState({
            errorMessage: null
        });
    }

    requestEmailFailed(errorMessage){
        this.setState({
            errorMessage: errorMessage
        });
    }
}

export default alt.createStore(AuthStore, 'AuthStore');
