import {Component} from 'react';
import AltContainer from 'alt-container';
import AuthStore from 'stores/AuthStore';
import HeaderDetails from './HeaderDetails';


export default class Header extends Component {
    render() {
        return (
            <AltContainer store={AuthStore}>
                <HeaderDetails />
            </AltContainer>);
    }
}
